import { useTheme } from '@emotion/react';

import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';

import { fDate } from 'src/utils/format-time';

import { useTable, TablePaginationCustom } from 'src/components/table';
import { useTranslate } from 'src/locales';

export function SummaryRecentTransitions({ timeline }) {
  const table = useTable({ defaultRowsPerPage: 5 });
  const theme = useTheme();
  const { t } = useTranslate();
  const dataFiltered = timeline;
  const showPagination = dataFiltered?.length > 5;
  const dataInPage = showPagination
    ? dataFiltered?.slice(
        table?.page * table?.rowsPerPage,
        table?.page * table?.rowsPerPage + table?.rowsPerPage
      )
    : dataFiltered;
  if (dataInPage.length === 0) {
    return null;
  }

  return (
    <>
      {dataInPage?.length > 0 && (
        <Typography
          variant="h6"
          sx={{ display: 'flex', mt: 3, alignItems: 'center', justifyContent: 'space-between' }}
        >
          {t('alerts.keyDates')}
          {showPagination && (
            <TablePaginationCustom
              count={dataFiltered?.length}
              page={table?.page}
              rowsPerPage={table?.rowsPerPage}
              rowsPerPageOptions={[]}
              onPageChange={table?.onChangePage}
              onRowsPerPageChange={table?.onChangeRowsPerPage}
            />
          )}
        </Typography>
      )}

      <TableContainer component={Paper}>
        <Table
          size="small"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0px',
            '& td, & th': {
              borderBottom: `1px dashed ${theme?.palette?.divider}`,
            },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" />
              <TableCell />
            </TableRow>
            {dataInPage?.map((row, index) => (
              <TableRow key={row?.type + index}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ minWidth: t('demo.lang') === 'PT' ? 170 : 130 }}
                >
                  <Typography variant="subtitle2" sx={{ py: 1 }}>
                    {fDate(row?.date)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ py: 1, fontWeight: 400 }}>
                    {row?.description}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showPagination && (
        <TablePaginationCustom
          count={dataFiltered?.length}
          page={table?.page}
          rowsPerPage={table?.rowsPerPage}
          rowsPerPageOptions={[]}
          onPageChange={table?.onChangePage}
          onRowsPerPageChange={table?.onChangeRowsPerPage}
        />
      )}
    </>
  );
}
