import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Iconify } from 'src/components/iconify';
import { POLICY_STATUS_COLOR, POLICY_STATUS_OPTIONS } from 'src/constant/filters';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';

export default function PolicyStatusDropdown({
  list = [],
  isInternalPolicies,
  handleClosePopover,
}) {
  const { userData } = useAuthContext();
  const { t } = useTranslate();
  // Use useMemo to avoid recalculating lists on every render
  const router = useRouter();
  const { pendingList, approvedList } = useMemo(() => {
    const filteredList = list.filter((data) =>
      isInternalPolicies ? !data?.is_regulation : data?.is_regulation
    );

    return {
      pendingList: filteredList.filter(
        (item) => item?.status?.toLowerCase() === POLICY_STATUS_OPTIONS[0]
      ),
      approvedList: filteredList.filter(
        (item) =>
          new Date(item.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) &&
          item?.status?.toLowerCase() !== POLICY_STATUS_OPTIONS[0]
      ),
    };
  }, [list, isInternalPolicies]);

  // Common styles extracted to constants
  const menuItemStyles = {
    mb: 1,
    height: '72px',
    padding: '12px',
    borderRadius: 1,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',
    '&:hover': {
      background: '#FFFFFF', // Same as default background
    },
  };

  const primaryTypographyStyles = {
    color: '#1C252E',
    mb: 0.5,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  };

  // Render menu item component to reduce code duplication
  const renderMenuItem = (option, isPending = false) => (
    <Box sx={{ mb: 1 }} key={option.id}>
      <MenuItem key={option.id} value={option} sx={menuItemStyles}>
        <Box sx={{ display: 'flex', alignItems: 'top', width: '100%' }}>
          <Box
            sx={{ display: 'flex', alignItems: 'top', flex: 1 }}
            onClick={() => {
              if (option?.status?.toLowerCase() === POLICY_STATUS_OPTIONS[1]) {
                handleClosePopover();
                if (isInternalPolicies) {
                  router.push(`${paths.dashboard.internalPolicies}/${option.id}`);
                } else {
                  router.push(`${paths.dashboard.lawLibrary}/${option.id}`);
                }
              }
            }}
          >
            <Iconify
              icon={
                isPending
                  ? 'tabler:loader'
                  : option?.status === POLICY_STATUS_OPTIONS[2]
                    ? 'solar:danger-bold-duotone'
                    : 'solar:check-circle-bold-duotone'
              }
              sx={{
                mr: 1.5,
                width: 24,
                height: 24,
                color: isPending
                  ? '#8E33FF'
                  : option?.status === POLICY_STATUS_OPTIONS[2]
                    ? '#FF5630'
                    : '#118D57',
              }}
            />
            <ListItemText
              primary={option?.name}
              secondary={
                isPending
                  ? option?.status
                  : option?.status === POLICY_STATUS_OPTIONS[1]
                    ? `${POLICY_STATUS_OPTIONS[1]} successfully`
                    : option?.status
              }
              primaryTypographyProps={{
                variant: 'subtitle2',
                sx: primaryTypographyStyles,
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                sx: {
                  color:
                    option?.status === POLICY_STATUS_OPTIONS[2] && !isPending
                      ? '#FF5630'
                      : '#637381',
                },
              }}
            />
          </Box>
          {/* <Iconify icon="eva:more-vertical-fill" sx={{ color: '#919EAB' }} /> */}
        </Box>
      </MenuItem>
    </Box>
  );

  return (
    <Box
      sx={{
        background: '#F4F6F8',
        borderRadius: 2,
        pt: 1.5,
        px: 1.5,
        maxHeight: '550px',
        minWidth: '360px',
        overflow: 'auto',
      }}
    >
      {pendingList.length > 0 && (
        <Box
          sx={{
            mb: 2,
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Iconify
            icon="solar:hourglass-line-outline"
            sx={{ mx: 1.5, width: 16, height: 16, color: '#637381' }}
          />
          <Typography variant="subtitle2" sx={{ color: '#637381', fontWeight: 500 }}>
            {t('lawLibrarySection.documentsInProgress')}
          </Typography>
        </Box>
      )}
      {pendingList.map((option) => renderMenuItem(option, true))}

      {approvedList.length > 0 && (
        <Box
          sx={{
            mb: 2,
            mt: pendingList.length > 0 ? 4 : 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Iconify
            icon="solar:history-2-outline"
            sx={{ mx: 1.5, width: 16, height: 16, color: '#637381' }}
          />
          <Typography variant="subtitle2" sx={{ color: '#637381', fontWeight: 500 }}>
            {t('lawLibrarySection.recentlyProcessedDocuments')}
          </Typography>
        </Box>
      )}

      {approvedList.map((option) => renderMenuItem(option))}

      {pendingList.length === 0 && approvedList.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 3.5, // Add more vertical padding
            textAlign: 'center',
          }}
        >
          <Iconify
            icon="solar:folder-open-bold-duotone"
            sx={{
              width: 48,
              height: 48,
              color: '#919EAB',
              mb: 2,
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: '#1C252E',
              mb: 1,
            }}
          >
            {t('lawLibrarySection.yourListIsEmpty')}
          </Typography>
          <Typography
            sx={{
              color: '#637381',
              maxWidth: 300,
              fontFamily: 'Inter',
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '19.2px',
              letterSpacing: 'var(--subtitle2letter-spacing)',
              textAlign: 'center',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
            }}
          >
            {t('lawLibrarySection.noUploadedDocuments')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
