import { create } from 'zustand';
import axiosInstance, { endpoints } from 'src/utils/axios';
import {
  REQUIREMENT_DISCARD_FILTER,
  REQUIREMENT_PRE_FIX,
  REQUIREMENT_STATUS,
} from 'src/constant/law-library-constant';

const initialFilters = {
  name: '',
  businessImpacted: [],
  productImpacted: [],
  applicable: false,
  page: 0,
  selectedArticle: { part: null, article_id: [] },
  assignedUsers: [],
  status: [],
  hideDiscarded: false,
  starred: false,
  entities: [],
};

const articleCache = {}; // Cache for articles

const useRequirementStore = create((set, get) => ({
  requirements: [],
  requirementsFiltered: [],
  allEntities: [],
  policyId: null,
  policyDetails: null,
  statusCount: REQUIREMENT_STATUS,
  statusCountLoading: false,
  isLoading: false,
  error: null,
  totalCount: 0,
  timeStamp: null,
  filters: initialFilters,
  canReset: false,
  articleReset: false,
  canResetAll: false,
  requirmentCountInArticle: null,
  requirmentCountInArticleByPart: null,
  police: {
    policyArticlesFiltered: [],
    policyArticlesAll: [],
    isLoading: false,
    error: null,
  },
  autoSave: {
    saving: false,
    lastSaved: null,
    lastSavedTime: null,
  },
  setAutoSaveState: (state) => set({ autoSave: state }),
  setError: (error) => set({ error }),
  setPolicyId: (id) => {
    set({ policyId: id });
    get().initializeFilters(id);
  },
  fetchRequirements: async (id, page = 0) => {
    let allRequirements = [];
    let cursor = null;
    let totalCount = get().totalCount;
    set({ isLoading: totalCount ? false : true, statusCountLoading: true });
    cursor = allRequirements.length ? allRequirements[allRequirements.length - 1].id : null;

    // const { name, businessImpacted, productImpacted } = get().filters;

    try {
      do {
        const response = await axiosInstance.get(`${endpoints.rules.root}`, {
          params: {
            policy_id: id,
            cursor,
          },
        });
        const requirements = response?.data; //?.filter((item) => !(item?.extra?.discarded === true));
        const count = parseInt(response.headers['zango-api-count'], 10);
        if (!totalCount) {
          totalCount = count;
        }
        allRequirements = [...allRequirements, ...requirements];
        //  if(!totalCount){
        set({
          requirements: allRequirements,
          totalCount,
          isLoading: false,
          timeStamp: new Date().toISOString(),
          error: null,
        });
        get().applyFilter();
        // }
        cursor = requirements.length ? requirements[requirements.length - 1].id : null;
      } while (cursor && allRequirements.length < totalCount);
      // const entities = allRequirements.reduce((acc, requirement) => {
      //   if (requirement.entities && Array.isArray(requirement.entities)) {
      //     requirement.entities.forEach((entity) => {
      //       if (!acc.some((e) => e.text === entity.text)) {
      //         acc.push(entity);
      //       }
      //     });
      //   }
      //   return acc;
      // }, []);
      // set({
      //   allEntities: Array.from(new Set(entities)),
      // });
      set({
        requirements: allRequirements,
        totalCount,
        isLoading: false,
        timeStamp: new Date().toISOString(),
        error: null,
        statusCountLoading: false,
      });
      get().applyFilter();
    } catch (error) {
      set({
        error: error.message,
        isLoading: false,
        statusCountLoading: false,
      });
    }
  },

  fetchPolicyArticles: async (id) => {
    const policyId = id || get().policyId;
    if (!policyId) {
      return;
    }
    set({
      police: {
        policyArticlesFiltered: [],
        policyArticlesAll: [],
        isLoading: true,
        error: null,
      },
    });

    // Check session storage first
    const sessionKey = `policy_articles_${policyId}`;
    const cachedData = localStorage.getItem(sessionKey);

    // Only proceed with parsing if cachedData exists
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const currentTime = new Date().toISOString();
      const timestamp = parsedData?.timestamp;
      const oneDayAgo = new Date(currentTime);
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      if (timestamp && new Date(timestamp) > oneDayAgo) {
        const { allArticles, policyArticlesFiltered } = parsedData;
        set({
          police: {
            policyArticlesAll: allArticles,
            policyArticlesFiltered,
            isLoading: false,
            error: null,
          },
        });

        return;
      }
    }

    // If no valid cached data, continue with API request
    let allArticles = [];
    let cursor = null;
    let totalCount = 0;
    try {
      do {
        const response = await axiosInstance.get(
          `${endpoints.policy.root}/${policyId}${endpoints.policy.articles}`,
          {
            params: { cursor },
          }
        );
        const articles = response.data;
        const count = parseInt(response.headers['zango-api-count'], 10);
        if (!totalCount) {
          totalCount = count;
        }
        allArticles = [...allArticles, ...articles];
        cursor = articles.length ? articles[articles.length - 1].id : null;
      } while (cursor && allArticles.length < totalCount);

      // Helper function to compare article IDs
      const compareArticleIds = (a, b) => {
        // Handle case where article_id is null
        if (!a.article_id && !b.article_id) return 0;
        if (!a.article_id) return -1;
        if (!b.article_id) return 1;

        const aParts = a.article_id.split('.').map(Number);
        const bParts = b.article_id.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
          const aPart = aParts[i] || 0;
          const bPart = bParts[i] || 0;
          if (aPart !== bPart) return aPart - bPart;
        }
        return 0;
      };

      const policyArticlesFiltered = allArticles
        .map((art) => {
          if (!art.part) {
            art.part = 'Others';
          }
          return art;
        })
        .filter((article) => article.part)
        .reduce((acc, article) => {
          const key = `${article.part_prefix}-${article.part}`;
          const group = acc.find((item) => item.key === key);

          if (group) {
            group.value.push(article);
            group.value.sort(compareArticleIds);
          } else {
            acc.push({
              key,
              value: [article],
            });
          }
          return acc;
        }, [])
        .sort((a, b) => {
          const partA = a.key.split('-')[1];
          const partB = b.key.split('-')[1];

          const numA = parseInt(partA);
          const numB = parseInt(partB);

          // If both are valid numbers, compare them numerically
          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
          }

          // If only one is a number, the number comes first
          if (!isNaN(numA)) return -1;
          if (!isNaN(numB)) return 1;

          // If both are strings, compare them alphabetically
          return partA.localeCompare(partB);
        });

      // Store in session storage
      localStorage.setItem(
        sessionKey,
        JSON.stringify({
          allArticles,
          policyArticlesFiltered,
          timestamp: new Date().toISOString(),
        })
      );

      set({
        police: {
          policyArticlesAll: allArticles,
          policyArticlesFiltered,
          isLoading: false,
          error: null,
        },
      });
    } catch (error) {
      set({
        police: {
          error: error.message,
          isLoading: false,
        },
      });
    }
  },
  fetchPolicyDetails: async (id) => {
    try {
      const response = await axiosInstance.get(`/policies/${id}`);
      set({
        policyDetails: response.data,
      });
    } catch (error) {
      set({
        policyDetails: null,
      });
    }
  },

  addRequirement: async (requirement) => {
    try {
      const response = await axiosInstance.post(endpoints.rules.root, requirement);
      set({
        requirements: [...get().requirements, response.data],
      });
      get().applyFilter();
      return response;
    } catch (error) {
      throw error;
    }
  },
  addControl: async (ruleId, control) => {
    try {
      const requirement = get()?.requirements?.find((requirement) => requirement?.id === ruleId);
      let tempControls = [];
      if (requirement) {
        tempControls = [...requirement?.controls, control];
      } else {
        tempControls = [control];
      }
      const response = await get().updateMainFields(ruleId, {
        rule: { controls: tempControls },
      });
      return response?.data?.controls[response?.data?.controls?.length - 1];
    } catch (error) {
      throw error;
    }
  },
  updateExtraFields: async (id, updatedFields) => {
    // const requirementData = get().requirements.find((requirement) => requirement.id === id);

    try {
      const response = await axiosInstance.patch(`${endpoints.rules.root}/${id}`, {
        extra: { ...updatedFields },
      });
      get().updateRequirementState(id, updatedFields, true);

      return response;
    } catch (error) {
      throw error;
    }
  },
  updateMainFields: async (id, updatedFields) => {
    try {
      const response = await axiosInstance.patch(`${endpoints.rules.root}/${id}`, {
        ...updatedFields,
      });

      get().updateRequirementState(id, response.data, false);

      return response;
    } catch (error) {
      throw error;
    }
  },

  updateRequirementState: (id, updatedFields, isExtra = false) => {
    const updateRequirement = (requirement) => {
      if (requirement.id === id) {
        return isExtra
          ? { ...requirement, extra: { ...requirement.extra, ...updatedFields } }
          : { ...requirement, ...updatedFields };
      }
      return requirement;
    };

    const requirementTemp = get().requirements?.map(updateRequirement);

    set({
      requirements: requirementTemp,
    });
    get().applyFilter();
  },
  updateFilters: (newFilters) => {
    const currentFilters = get().filters;
    const updatedFilters = { ...currentFilters, ...newFilters };
    const canReset =
      !!updatedFilters.name ||
      updatedFilters.businessImpacted.length > 0 ||
      updatedFilters.productImpacted.length > 0 ||
      updatedFilters.hideDiscarded ||
      updatedFilters.starred;
    const articleReset = updatedFilters.selectedArticle.article_id.length > 0;
    set({
      filters: updatedFilters,
      canReset,
      canResetAll:
        canReset ||
        updatedFilters?.assignedUsers?.length > 0 ||
        updatedFilters?.status?.length > 0 ||
        articleReset ||
        updatedFilters?.applicable,
    });

    get().applyFilter();
  },

  resetFilters: () => {
    set({ filters: initialFilters, canReset: false, canResetAll: false });
    get().applyFilter();
  },
  resetArticleFilters: () => {
    set({
      filters: { ...get().filters, selectedArticle: { part: null, article_id: [] } },
      articleReset: false,
    });
    get().applyFilter();
  },
  applyFilter: () => {
    const { requirements, filters } = get();
    localStorage.setItem(`requirement-filters-${get().policyId}`, JSON.stringify(filters));

    const statusCount = REQUIREMENT_STATUS;
    statusCount.forEach((status) => {
      status.count = 0;
    });
    const {
      name,
      businessImpacted,
      productImpacted,
      applicable,
      entities,
      status,
      selectedArticle,
      assignedUsers,
      hideDiscarded,
      starred,
    } = filters;

    let filteredData = [...requirements];
    // .filter(
    //   (item) => get().getArticleDetailsById(item?.article_id)?.part
    // );
    if (hideDiscarded) {
      filteredData = filteredData.filter((item) => item?.extra?.discarded === true);
    } else {
      filteredData = filteredData.filter((item) => !(item?.extra?.discarded === true));
    }
    if (starred) {
      filteredData = filteredData.filter((item) => item?.extra?.star === true);
    }

    if (name) {
      filteredData = filteredData.filter(
        (item) =>
          item?.title?.toLowerCase().includes(name?.toLowerCase()) ||
          item?.text?.toLowerCase().includes(name?.toLowerCase()) ||
          `${REQUIREMENT_PRE_FIX[0]}${item?.short_id}`.toLowerCase().includes(name.toLowerCase())
      );
    }

    if (businessImpacted?.length) {
      filteredData = filteredData.filter((item) => {
        if (item?.extra?.businessImpacted) {
          return item?.extra?.businessImpacted.some((business) =>
            businessImpacted.includes(business)
          );
        } else {
          return false;
        }
      });
    }

    if (productImpacted?.length) {
      filteredData = filteredData.filter((item) => {
        if (item?.extra?.productImpacted) {
          return item?.extra?.productImpacted.some((product) => productImpacted.includes(product));
        } else {
          return false;
        }
      });
    }
    if (applicable) {
      filteredData = filteredData.filter((item) => item?.extra?.applicable);
    }

    if (selectedArticle?.article_id.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedArticle.article_id.includes(item.article_id)
      );
    }
    if (assignedUsers?.length > 0) {
      filteredData = filteredData.filter((item) => assignedUsers.includes(item?.user_id));
    }
    // if (entities?.length > 0) {
    //   filteredData = filteredData.filter((item) => {
    //     return entities.some((entity) => {
    //       return item?.entities?.some((e) => e?.text?.includes(entity));
    //     });
    //   });
    // }
    filteredData.forEach((item) => {
      const status = item?.extra?.status;
      if (!status || status === REQUIREMENT_STATUS[0].value) {
        statusCount[0].count++;
      } else if (status === REQUIREMENT_STATUS[1].value) {
        statusCount[1].count++;
      } else if (status === REQUIREMENT_STATUS[2].value) {
        statusCount[2].count++;
      } else if (status === REQUIREMENT_STATUS[3].value) {
        statusCount[3].count++;
      }
    });
    set({ statusCount });
    if (status?.length > 0) {
      filteredData = filteredData.filter((item) => {
        if (item?.extra?.status) {
          return status.includes(item?.extra?.status);
        } else {
          return status.includes(REQUIREMENT_STATUS[0].value);
        }
      });
    }
    const canReset =
      !!name ||
      businessImpacted.length > 0 ||
      productImpacted.length > 0 ||
      hideDiscarded ||
      starred;
    set({ requirementsFiltered: filteredData, canReset });
    get().calculateCountInArticle();

    // return filteredData;
  },
  calculateCountInArticle: () => {
    const { policyArticlesAll, policyArticlesFiltered } = get().police;
    const requirementCountMap = new Map();
    const articleCountMap = new Map();

    policyArticlesAll?.forEach((item) => {
      const requirementsForArticle = get().requirements?.filter(
        (requirement) => item?.id === requirement.article_id && requirement?.extra?.applicable
      );
      const totalCount = requirementsForArticle?.length;
      const count = requirementsForArticle?.filter(
        (requirement) => requirement?.extra?.status === REQUIREMENT_STATUS[3].value
      ).length;
      requirementCountMap.set(item.id, {
        totalCount,
        count,
        requirementsForArticle,
        isCompleted: totalCount > 0,
      });
    });
    policyArticlesFiltered?.forEach((item) => {
      let totalCount = 0;
      let count = 0;
      item?.value?.forEach((article) => {
        totalCount += requirementCountMap.get(article.id)?.totalCount;
        count += requirementCountMap.get(article.id)?.count;
      });
      articleCountMap.set(item.key, {
        totalCount,
        count,
        requirementsForPart: item.value,
        isCompleted: totalCount > 0,
      });
      // const requiremnetByPart = item?.value?.filter((article) => article?.extra?.applicable);
      // const totalCount = requiremnetByPart?.length;
      // const count = requiremnetByPart.filter((article) => article?.extra?.status === REQUIREMENT_STATUS[3].value).length;
    });

    set({
      requirmentCountInArticle: requirementCountMap,
      requirmentCountInArticleByPart: articleCountMap,
    });
  },
  getArticleById: (articleId, imageId = false) => {
    // Check if the article is already in the cache
    if (articleCache[articleId] && !imageId) {
      return articleCache[articleId];
    }

    // If not in cache, search in policyArticles
    const article = get()?.police?.policyArticlesAll?.find((art) => art.id === articleId);

    if (imageId && article) {
      return article.image_id || null;
    }
    // Store the found article in the cache
    if (article) {
      articleCache[articleId] =
        (article?.part_prefix
          ? article?.part_prefix?.charAt(0)?.toUpperCase() + article?.part_prefix?.slice(1) + ' '
          : '') +
        (article?.part ? article?.part + ' ' : '') +
        (article?.id_prefix
          ? article?.id_prefix?.charAt(0)?.toUpperCase() + article?.id_prefix?.slice(1) + ' '
          : '') +
        (article?.article_id ? article?.article_id : '');
    }

    return articleCache?.[articleId] || ''; // Return the article or null if not found
  },
  getArticleDetailsById: (articleId) => {
    const article = get()?.police?.policyArticlesAll?.find((art) => art.id === articleId);
    return article || null;
  },
  initializeFilters: (policyId) => {
    try {
      const savedFilters = localStorage.getItem(`requirement-filters-${policyId}`);
      set({ filters: savedFilters ? JSON.parse(savedFilters) : initialFilters });
    } catch (error) {
      console.error('Error loading filters from localStorage:', error);
      set({ filters: initialFilters });
    }
  },
}));

export default useRequirementStore;
