import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { signOut } from 'src/auth/context/firebase/action';
import { Iconify } from 'src/components/iconify';
import { NavItem } from 'src/components/nav-section/vertical/nav-item';
import { Avatar, Box, Divider, Stack } from '@mui/material';
import { LanguagePopover } from './language-popover';
import { USER_TYPE } from 'src/config-global';
import { CustomPopover, usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export function SignOutButton({ ...other }) {
  const router = useRouter();
  const popover = usePopover();

  const { checkUserSession, userType, userData } = useAuthContext();

  const handleLogout = useCallback(async () => {
    try {
      localStorage.clear();
      await signOut();
      await checkUserSession?.();

      // onClose?.();
      router.refresh();
    } catch (error) {}
  }, [checkUserSession, router]);

  return (
    <>
      <Stack sx={{ gap: '4px' }}>
        {/* <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}> */}
        {userType === USER_TYPE[0] && (
          <>
            <LanguagePopover data-slot="localization" type="custom" data={[]} />
          </>
        )}
        <Button
          disableRipple
          color="inherit"
          onClick={handleLogout}
          startIcon={<Iconify icon="solar:logout-2-outline" width={24} height={24} />}
          sx={{
            padding: '4px 8px 4px 12px',
            minWidth: '100%',

            color: 'var(--text-secondary, #637381)',
            justifyContent: 'flex-start',
            height: '44px',
            '& .MuiButton-startIcon': {
              marginRight: 1,
            },
            '& .MuiButton-endIcon': {
              marginLeft: 'auto',
            },
          }}
        >
          {'Logout'}
        </Button>
        {/* <Button
          disableRipple
          color="inherit"
          startIcon={
            <Avatar sx={{ width: 24, height: 24 }}>
              <Box
                sx={{
                  color: 'var(--action-active, #637381)',
                  textAlign: 'center',
                  fontFamily: 'var(--caption-family, Inter)',
                  fontSize: 'var(--caption-size, 12px)',
                  fontStyle: 'normal',
                  fontWeight: 'var(--weight-500, 500)',
                  lineHeight: 'var(--caption-line-height, 18px)',
                  letterSpacing: 'var(--caption-letter-spacing, 0px)',
                }}
              >
                {userData?.first_name?.charAt(0) || ''}
              </Box>
            </Avatar>
          }
          sx={{
            padding: '4px 8px 4px 12px',
            minWidth: '100%',

            color: 'var(--text-secondary, #637381)',
            justifyContent: 'flex-start',
            height: '44px',
            '& .MuiButton-startIcon': {
              marginRight: 1,
            },
            '& .MuiButton-endIcon': {
              marginLeft: 'auto',
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0 }}>
            <Box
              sx={{
                color: 'var(--text-primary, #101828)',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0px',
              }}
            >
              {userData?.first_name + ' ' + userData?.last_name}
            </Box>

            <Box
              sx={{
                color: 'var(--text-secondary, #637381)',
                fontSize: '12px',
                fontWeight: '500',

                letterSpacing: '0px',
              }}
            >
              {userData?.email}
            </Box>
          </Box>
        </Button> */}
      </Stack>
    </>
  );
}
