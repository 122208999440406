import Stack from '@mui/material/Stack';

import { SignOutButton } from './sign-out-button';
import { useAuthContext } from 'src/auth/hooks';
import { Avatar, Box, Button, Divider } from '@mui/material';

// ----------------------------------------------------------------------

export function NavUpgrade({ sx, ...other }) {
  const { userData } = useAuthContext();

  return (
    <>
      <Box sx={{ p: 0, bottom: 0, position: 'absolute', width: '100%' }}>
        <Divider sx={{ borderStyle: 'dashed', my: 1 }} />

        <Stack sx={{ px: 2, textAlign: 'left', ...sx }} {...other}>
          <Stack alignItems="left">
            <SignOutButton />
          </Stack>
        </Stack>
        <Box sx={{ p: 2, m: 0, backgroundColor: 'var(--grey-8, rgba(145, 158, 171, 0.08))' }}>
          <Button
            disableRipple
            color="inherit"
            startIcon={
              <Avatar sx={{ width: 24, height: 24 }}>
                <Box
                  sx={{
                    color: 'var(--action-active, #637381)',
                    textAlign: 'center',
                    fontFamily: 'var(--caption-family, Inter)',
                    fontSize: 'var(--caption-size, 12px)',
                    fontStyle: 'normal',
                    fontWeight: 'var(--weight-500, 500)',
                    lineHeight: 'var(--caption-line-height, 18px)',
                    letterSpacing: 'var(--caption-letter-spacing, 0px)',
                  }}
                >
                  {userData?.first_name?.charAt(0) || ''}
                </Box>
              </Avatar>
            }
            sx={{
              padding: '4px 8px 4px 12px',
              minWidth: '100%',

              color: 'var(--text-secondary, #637381)',
              justifyContent: 'flex-start',
              height: '44px',
              '& .MuiButton-startIcon': {
                marginRight: 1,
              },
              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0 }}
            >
              <Box
                sx={{
                  color: 'var(--text-primary, #101828)',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '0px',
                }}
              >
                {userData?.first_name + ' ' + userData?.last_name}
              </Box>

              <Box
                sx={{
                  color: 'var(--text-secondary, #637381)',
                  fontSize: '12px',
                  fontWeight: '500',

                  letterSpacing: '0px',
                }}
              >
                {userData?.email}
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

// export function UpgradeBlock({ sx, ...other }) {
//   return (
//     <Stack
//       sx={{
//         ...bgGradient({
//           color: `135deg, ${hexAlpha('#F7BB95', 0.92)}, ${hexAlpha('#5B2FF3', 0.92)}`,
//           imgUrl: `${CONFIG.site.basePath}/assets/background/background-7.webp`,
//         }),
//         px: 3,
//         py: 4,
//         borderRadius: 2,
//         position: 'relative',
//         ...sx,
//       }}
//       {...other}
//     >
//       <Box
//         sx={{
//           top: 0,
//           left: 0,
//           width: 1,
//           height: 1,
//           borderRadius: 2,
//           position: 'absolute',
//           border: (theme) => `solid 3px ${varAlpha(theme.vars.palette.common.whiteChannel, 0.16)}`,
//         }}
//       />

//       <Box
//         component={m.img}
//         animate={{ y: [12, -12, 12] }}
//         transition={{
//           duration: 8,
//           ease: 'linear',
//           repeat: Infinity,
//           repeatDelay: 0,
//         }}
//         alt="Small Rocket"
//         src={`${CONFIG.site.basePath}/assets/illustrations/illustration-rocket-small.webp`}
//         sx={{ right: 0, width: 112, height: 112, position: 'absolute' }}
//       />

//       <Stack alignItems="flex-start" sx={{ position: 'relative' }}>
//         <Box component="span" sx={{ typography: 'h5', color: 'common.white' }}>
//           35% OFF
//         </Box>

//         <Box
//           component="span"
//           sx={{
//             mb: 2,
//             mt: 0.5,
//             color: 'common.white',
//             typography: 'subtitle2',
//           }}
//         >
//           Power up Productivity!
//         </Box>

//         <Button variant="contained" size="small" color="warning">
//           Upgrade to Pro
//         </Button>
//       </Stack>
//     </Stack>
//   );
// }
