import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Divider,
  IconButton,
  Skeleton,
} from '@mui/material';
import { Iconify } from 'src/components/iconify';
import useRequirementStore from 'src/zustand-store/requirement-store/requirement-store';
import { toast } from 'sonner';
import { EmptyContent } from 'src/components/empty-content';
import { REQUIREMENT_PRE_FIX } from 'src/constant/law-library-constant';
import { useTranslate } from 'src/locales';

const RequirementSourceDrawerContent = ({ text, isLoading, source, onClose }) => {
  const { t } = useTranslate();

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="h6">{t('lawLibrarySection.sourceDocument')}</Typography>

        <IconButton onClick={onClose}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </Stack>
      <Divider />
      <Stack
        direction="column"
        alignItems="start"
        justifyContent="space-between"
        gap={2}
        sx={{ p: 2.5 }}
      >
        <Typography
          sx={{
            color: 'var(--text-primary, #1C252E)',
            fontFamily: 'Inter',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '160%' /* 20.8px */,
            letterSpacing: 'var(--body2-letter-spacing, 0px)',
          }}
        >
          {'Reference text'}
        </Typography>
        <Typography
          sx={{
            color: 'var(--text-primary, #1C252E)',
            fontFamily: 'Inter',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '160%' /* 20.8px */,
            letterSpacing: 'var(--body2-letter-spacing, 0px)',
          }}
        >
          {text}
        </Typography>
      </Stack>
      <Box sx={{ px: 2.5 }}>
        <Typography
          sx={{
            color: 'var(--text-primary, #1C252E)',
            fontFamily: 'Inter',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '160%' /* 20.8px */,
            letterSpacing: 'var(--body2-letter-spacing, 0px)',
            marginBottom: '10px',
          }}
        >
          {t('lawLibrarySection.referenceInDocument')}
        </Typography>
        {isLoading ? (
          <Stack spacing={2} alignItems="center">
            <Skeleton
              variant="rectangular"
              width="100%"
              height={700}
              animation="wave"
              sx={{
                backgroundColor: 'grey.300',
              }}
            />
          </Stack>
        ) : source ? (
          <Stack spacing={2}>
            <Box
              component="img"
              src={source.url}
              alt={source.title}
              sx={{ width: '100%', height: 'auto', mb: 2 }}
            />
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <EmptyContent
              title={t('lawLibrarySection.noSourceData')}
              description={t('lawLibrarySection.noSourceInfoAvailable')}
              sx={{ py: 3 }}
            />
          </Stack>
        )}
      </Box>
    </>
  );
};

export default RequirementSourceDrawerContent;
