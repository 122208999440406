import { onAuthStateChanged } from 'firebase/auth';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { useSetState } from 'src/hooks/use-set-state';

import axios from 'src/utils/axios';

import { AUTH } from 'src/lib/firebase';
import { getUserData } from 'src/actions/polices';

import { AuthContext } from '../auth-context';
import { getUser } from 'src/utils/user-type-check';
import { getIdTokenResult, signOut } from './action';
import { CONFIG, ORG_LIST, USER_TYPE } from 'src/config-global';
import { toast } from 'sonner';

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const { state, setState } = useSetState({
    user: null,
    loading: true,
  });

  const [userData, setUserData] = useState(null);
  const [org_id, setOrgId] = useState(null);
  const [orgDetails, setOrgDetails] = useState(null);
  const [isMultiLang, setIsMultiLang] = useState(false);
  const [userType, setUserType] = useState(null);

  // Add token refresh effect
  useEffect(() => {
    // Set up token refresh interval
    const refreshTokenInterval = setInterval(async () => {
      try {
        const currentUser = AUTH.currentUser;
        if (currentUser) {
          // Force token refresh
          const newToken = await currentUser.getIdToken(true);
          // Update axios header with new token
          axios.defaults.headers.common['x-api-key'] = newToken;
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }, CONFIG.firebase.refreshTokenInterval); // 2 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(refreshTokenInterval);
  }, []);

  const checkUserSession = useCallback(async () => {
    try {
      const unsubscribe = onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const { accessToken } = user;
          const result = await getIdTokenResult();
          const subdomainType = result?.claims?.subdomain;
          const org_id = result?.claims?.org_id || null;
          setOrgId(org_id);
          const orgDetails = ORG_LIST.find((org) => org.org_id === org_id) || {
            ...ORG_LIST[0],
            org_id: org_id,
          };
          setOrgDetails(orgDetails);
          axios.defaults.headers.common['x-api-key'] = `${accessToken}`;
          const subdomain = window.location.hostname.split('.')[0];

          // Case 1: For 'nb' subdomain
          if (subdomain === USER_TYPE[0]) {
            if (subdomainType !== USER_TYPE[0]) {
              signOut();
              toast.error('You are not authorized to access this page');
            }
          }
          // Case 2: For 'copilot' subdomain
          else if (subdomain === USER_TYPE[1]) {
            if (subdomainType === USER_TYPE[0]) {
              signOut();
              toast.error('You are not authorized to access this page');
            }
          }

          const isNovaBrand = subdomainType === subdomain && subdomain === USER_TYPE[0];

          // Fetch additional user data from your backend
          try {
            localStorage.setItem('user_id', user.uid);

            // Check if clarity is defined
            if (window?.clarity) {
              // Set user properties
              window.clarity('set', 'unique_id', user.uid.slice(-5));
            }

            const response = await getUserData(user.uid);

            setUserData(response);
            const userBasedOnDomain = getUser();
            setIsMultiLang(isNovaBrand);
            setUserType(isNovaBrand ? subdomain : null);

            // setIsMultiLang(true || isNovaBrand);
            // setUserType('nb'); //|| isNovaBrand ? subdomain : null);

            // setIsMultiLang(subdomainType === USER_TYPE[0]);
            // setUserType(subdomainType);
          } catch (error) {}
          setState({ user: { ...user }, loading: false });
        } else {
          setState({ user: null, loading: false });
          setUserData(null);
          setIsMultiLang(false);
          setUserType(null);
          setOrgDetails(null);
          setOrgId(null);
          delete axios.defaults.headers.common['x-api-key'];
        }
      });

      // Return the unsubscribe function
      return unsubscribe;
    } catch (error) {
      setState({ user: null, loading: false });
      setUserData(null);
      setIsMultiLang(false);
      setUserType(null);
      setOrgDetails(null);
      setOrgId(null);
    }
  }, [setState]);

  useEffect(() => {
    const unsubscribe = checkUserSession();
    // Cleanup function to unsubscribe when component unmounts
    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [checkUserSession]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user
        ? {
            ...state.user,
            id: state.user?.uid,
            accessToken: state.user?.accessToken,
            displayName: state.user?.displayName,
            photoURL: state.user?.photoURL,
            role: state.user?.role ?? 'admin',
          }
        : null,
      userData,
      isMultiLang,
      userType,
      org_id,
      orgDetails,
      checkUserSession,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [checkUserSession, state.user, status, userData, userType]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
// https://copilot.zango.ai/auth/firebase/change-password/?email=%EMAIL%
